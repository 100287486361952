type FlagAbTest = {
    [variation: string]: string;
    flagKey: string;
    variationA: string;
    variationB: string;
};

export const plpValentinePage: FlagAbTest = {
    flagKey: 'b4-plp-valentines-layout',
    variationA: 'default',
    variationB: 'inspiration_without_banner',
    variationC: 'inspiration_with_banner',
};
